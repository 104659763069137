@import "reset";

$white: #fff;

$pink: #e47fdd;

$green: #169b00;

$yellow: #cefe00;

$purple: #201830;

$violet: #261f34;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400&display=swap');
$opensans: 'Open Sans', sans-serif;

@font-face {
  font-family: Gotham;
  src: local('Gotham'), url("/fonts/Gotham.otf") format("opentype");
}
$gotham: Gotham, sans-serif;
