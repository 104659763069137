@import "common";


html, body {
  background-color: $purple;
}


.button {
  $move: 7.5px;
  $opacity: .65;
  $font-family: $opensans;
  $font-weight: 600;
  $font-size: 20px;
  $primary: $green;
  $secondary: $yellow;
  $transition: background-color .1s ease-in-out;


  background-color: $secondary;
  border-radius: 25px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: $transition;

  & > div:first-child {
    color: $secondary;
    font-family: $font-family;
    font-size: $font-size;
    font-weight: $font-weight;
    z-index: 1;
    transform: translate($move / 2, $move / 4);
    padding: 15px 25px;
    position: relative;
  }

  & > div:nth-child(2) {
    background-color: $primary;
    border-radius: 25px;
    z-index: 0;
    opacity: $opacity;
    height: 100%;
    width: 100%;
    position: absolute;
    top: $move;
    left: $move / 2;
  }
}

.button:hover {
  $primary: $yellow;
  $secondary: $green;


  background-color: $secondary;

  & > div:first-child {
    color: $secondary;
  }

  & > div:nth-child(2) {
    background-color: $primary;
  }
}


.title {
  $move: 5px;
  $primary: $green;
  $secondary: $yellow;


  position: relative;
  cursor: pointer;
  transition: all 0.3s;

  & > div {
    font-family: $gotham;
  }

  & > div:first-child {
    color: $secondary;
    position: relative;
    z-index: 0;
  }

  & > div:last-child {
    color: $primary;
    position: absolute;
    top: $move;
    z-index: 1;
  }
}


.paragraph {
  font-family: $opensans;
  font-size: 16px;
  line-height: 1.4em;

  & > b {
    font-weight: 700;
  }
}


.cover {
  $breakpoint1: 1460px;
  $breakpoint2: 885px;
  $breakpoint3: 425px;


  position: relative;
  display: flex;
  flex-wrap: wrap;
  z-index: 0;
  background-image: url(/images/blue.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  overflow: hidden;

  & > div:first-child {
    position: relative;
    z-index: 1;
    width: 50%;

    & > img {
      width: 120%;
      max-width: 1050px;
      left: -20%;
      top: 0;
    }

    & > img:first-child {
      position: relative;
    }

    & > img:not(& > img:first-child) {
      position: absolute;
    }
  }

  & > div:nth-child(2) {
    position: relative;
    z-index: 2;
    width: 50%;
    display: flex;
    flex-direction: column;

    & > div:first-child {
      width: 100%;
      height: 50%;
      max-height: 50vh;
    }

    & > div:nth-child(2) {
      width: 30vw;
      max-width: 1060px;
      position: relative;
      box-sizing: border-box;
      transform: translateY(-50%);
      margin-bottom: -50%;
      left: 0;

      .paragraph {
        color: $yellow;
      }
    }
  }

  & > div:nth-child(3) {
    z-index: 0;
    width: 100%;
    height: 10vh;
  }

  @media (max-width: $breakpoint1) {
    & > div:nth-child(2) {
      & > div:nth-child(2) {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  @media (max-width: $breakpoint2) {
    flex-direction: column;

    & > div:first-child {
      width: 100%;

      & > img {
        width: 80%;
        left: 10%;
      }
    }

    & > div:nth-child(2) {
      width: 80%;
      left: 10%;

      & > div:first-child {
        display: none;
      }

      & > div:nth-child(2) {
        width: 100%;
        padding: 0vh 50px 15vh;
        margin-bottom: 0;
        transform: none;
        left: 0;
      }
    }

    & > div:nth-child(3) {
      display: none;
    }
  }

  @media (max-width: $breakpoint3) {
    & > div:first-child {
      & > img {
        width: 120%;
        left: -10%;
      }
    }

    & > div:nth-child(2) {
      width: 100%;
      left: 0;
    }
  }
}


.card {
  $breakpoint1: 1000px;
  $breakpoint2: 600px;


  background-image: url(/images/orange.jpeg);
  background-position: 5vh 5vh;
  background-size: cover;
  width: 60vw;
  max-width: 715px;
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
  box-sizing: border-box;
  padding: 120px 50px 100px calc(50px + 5vh);
  -webkit-box-shadow: 0px 0px 47px 1px rgba(0, 0, 0, .6);
  -moz-box-shadow: 0px 0px 47px 1px rgba(0, 0, 0, .6);
  box-shadow: 0px 0px 47px 1px rgba(0, 0, 0, .6);
  transform: translateY(-5vh);
  margin-bottom: -5vh;

  & > :nth-child(3) {
    display: flex;
    gap: 30px;

    & > div {
      box-sizing: border-box;
      width: 50%;

      .paragraph {
        color: $white;
      }

      .paragraph:first-letter {
        float: left;
        font-size: 4em;
        line-height: 64px;
        text-transform: uppercase;
        box-sizing: border-box;
        margin-right: 10px;
        font-weight: 700;
      }
    }
  }

  @media (max-width: $breakpoint1) {
    max-width: none;
    width: 100%;
    margin: 0;
    top: 0;
    transform: none;
    margin-bottom: 0;
  }

  @media (max-width: $breakpoint2) {
    & > :nth-child(3) {
      flex-direction: column;

      & > div {
        width: 100%;
      }
    }
  }
}


.footer {
  $breakpoint1: 550px;


  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10vh 5vh;
  box-sizing: border-box;
  gap: 30px;
  margin: 0 auto;
  position: relative;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;

  & > div:nth-child(2) {
    a {
      text-decoration-color: $yellow;
    }

    .paragraph {
      color: $yellow;
    }
  }
}
